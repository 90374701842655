import Vue from "vue";
import VueSSE from "vue-sse";
import VueI18n from "vue-i18n";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./utils/api.vue";
import firebase from "./plugins/firebase";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;
Vue.mixin(api);

// i18n
Vue.use(VueI18n);

// Server Side Events
Vue.use(VueSSE);
Vue.use(firebase);

// GTAG
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-YRXYEHS58L"
    }
  },
  router
);

// Adicione suporte ao GTM
router.afterEach((to) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: to.fullPath,
  });
});

// Special thanks to FlowingSPDG for translations.
const translations = require("./translations/translations.json");

const i18n = new VueI18n({
  locale: "pt", // Use English by default
  messages: translations
});

Vue.filter("translate", function(value) {
  return i18n.t(value);
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister(); // Desregistra todos os Service Workers
    });
  });
}

new Vue({
  store,
  router,
  i18n: i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
