<template>
  <v-app>
    <Navbar />
    <SubTitleNav />
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
//import { mapActions } from "vuex";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import SubTitleNav from "@/components/SubTitleNav.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    SubTitleNav
  },
  methods: {
    //...mapActions(["fetchUser"])
  },
  async created() {
    //await this.fetchUser();
  }
};
</script>
<style>
.v-application {
  font-family: "Play", sans-serif !important;

  .title {
    font-family: "Play", sans-serif !important;
  }
  .headline {
    font-family: "Play", sans-serif !important;
  }
  .v-main {
    padding: 10px !important;
  }
}
.v-application a {
  color: #a3a3a3 !important;
}
</style>
